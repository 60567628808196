import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';

const Sidebar = ({ modules, onModuleClicked, onStartModule, selectedModuleId }) => {

  const [isSidebarVisible, setSidebarVisible] = React.useState(window.innerWidth < 991 ? false : true);
  const [isSidebarBtnActive, setSidebarBtnActive] = React.useState(window.innerWidth < 991 ? true : false);
 // const [openModules, setOpenModules] = useState(modules.modules && modules.modules.length > 0 ? [modules.modules[0]._id] : []);
  const [openModules, setOpenModules] = useState([]);
  const sidebarRef = useRef(null);
  const { t } = useTranslation();

 useEffect(() => {
    if (modules.modules && modules.modules.length > 0) {
     modules.modules.map((element, index) => {
        if(element.is_started){
           setOpenModules([element._id]);
           setTimeout(()=> {
              sidebarScroll();
           },10)
          console.log("current", [element._id]);
        }
     });
    } 
  
  }, [modules]);
  
  // useEffect(() => {
  //   if (modules.modules && modules.modules.length > 0) {
  //     setOpenModules(prevOpenModules => {
  //       const updatedOpenModules = modules.modules.reduce((acc, element) => {
  //         if (element.is_started || (element.is_completed && !element.is_completed)) {
  //           //acc.push(element._id);
  //           setOpenModules([element._id]);
  //           console.log("current", element._id);
  //         }
  //         return acc;
  //       }, [...prevOpenModules]);
  
  //       return updatedOpenModules;
  //     });
  //   } 
  // }, [modules]);

  const toggleModule = (moduleId) => {
    setOpenModules((prevOpenModules) => {
      if (prevOpenModules.includes(moduleId)) {
        return prevOpenModules.filter((id) => id !== moduleId);
      } else {
        return [...prevOpenModules, moduleId];
      }
    }); 
  };

  const sidebarScroll = (selectedModuleId) => {
    const sidebar = document.querySelector('.module-list');
    const activeButton = sidebar?.querySelector('.active');

    if (activeButton) {
      const activeButtonRect = activeButton?.getBoundingClientRect();
      const sidebarRect = sidebar?.getBoundingClientRect();
      const activeButtonTop = activeButtonRect?.top;
      const activeButtonBottom = activeButtonRect?.bottom;
      const sidebarTop = sidebarRect?.top;
      const sidebarBottom = sidebarRect?.bottom;
      const scrollPosition = activeButtonBottom - sidebarBottom;
  
      // console.log('activeButtonTop', activeButtonTop );
      // console.log('sidebarTop', sidebarTop);
      // console.log('scrollPosition', scrollPosition);

      if (scrollPosition >= 0) {
        const sidebarHeight = sidebar?.clientHeight;
      //  const scrollAmount = sidebarHeight * 0.9;
        sidebar.scrollBy(0, scrollPosition + 350);
      }
    }
  };

  const checkMediaQuery = () => {
    if (window.innerWidth < 991) {
     handleToggleSidebar();
    }
  };

  const handleModuleClick = (module) => {
    console.log("ddddd",module);
    if(module?.is_completed ||module?.is_started){
    onModuleClicked(module);
    toggleModule(module._id);
    checkMediaQuery();
   // sidebarScroll();
  }
  };

  const handleToggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    setSidebarBtnActive(!isSidebarBtnActive);
  };

  const onModuleClick =(data)=>{
    if(data?.is_completed){
      onModuleClicked(data);
      sidebarScroll();
    }
    // else{
    //   onModuleClicked(data)
    //   // onStartModule(data)
    // }
    // console.log(data);

   // console.log(data.title);

  }

  const getModuleIcon = (subModule) => {
    const hasNestedSubmodules = subModule.sub_modules && subModule.sub_modules.length > 0;

    if (hasNestedSubmodules) {
      return (
        <span className="me-2 w-10">
          <img src="/images/stack.svg" alt="stack" className='w-75' />
        </span>
      );
    }

    const moduleType = subModule.module_type;

    if(subModule.is_completed){
      return (
        <span className="me-2">
          <img src="/images/completed.svg" alt="completed" className='w-75 completed' />
        </span>
      );
    }

    //  console.log(modules);
    switch (subModule.module_type) {
      
      case 'video':
        return (
          <span className="me-2 w-10">
            <svg className='icon video' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className='icon-color' fill-rule="evenodd" clip-rule="evenodd" d="M14 0C6.27316 0 0 6.27316 0 14C0 21.7268 6.27316 28 14 28C21.7268 28 28 21.7268 28 14C28 6.27316 21.7268 0 14 0Z" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8129 13.9866L12.1143 10.1876V17.7857L17.8129 13.9866ZM18.9642 13.2648C19.4793 13.6082 19.4793 14.365 18.9642 14.7084L12.2236 19.2022C11.6472 19.5865 10.875 19.1732 10.875 18.4803V9.4929C10.875 8.80008 11.6472 8.38685 12.2236 8.77118L18.9642 13.2648Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.033 9.05693C11.685 8.82494 11.2189 9.07438 11.2189 9.49258V18.48C11.2189 18.8983 11.6851 19.1477 12.033 18.9158L18.7735 14.422C19.0845 14.2147 19.0845 13.7579 18.7735 13.5506L12.033 9.05693ZM12.4144 8.48478C11.6095 7.94812 10.5312 8.52514 10.5312 9.49258V18.48C10.5312 19.4474 11.6094 20.0246 12.4144 19.4879C12.4144 19.4879 12.4144 19.4879 12.4144 19.4879L19.155 14.9942C19.8742 14.5147 19.8742 13.4579 19.155 12.9784L12.4144 8.48478C12.4144 8.48478 12.4144 8.48478 12.4144 8.48478ZM11.7706 9.54479L18.4328 13.9862L11.7706 18.4278V9.54479ZM12.4582 10.8297V17.1429L17.1931 13.9862L12.4582 10.8297Z" fill="white" />
            </svg>
          </span>
        );
      case 'content':
        return (
          <span className="me-2 w-10">
            <svg className='icon notes' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className='icon-color' fill-rule="evenodd" clip-rule="evenodd" d="M14 0C21.7268 0 28 6.27319 28 14C28 21.7268 21.7268 28 14 28C6.27319 28 0 21.7268 0 14C0 6.27319 6.27319 0 14 0ZM14.5304 20.3323C14.3821 20.442 14.1986 20.5069 14 20.5069C14 20.5069 13.8604 20.5273 13.6595 20.4394C13.5913 20.4113 13.5273 20.375 13.4688 20.3316L13.4631 20.3277C12.5449 19.6752 11.4464 19.3248 10.3201 19.3248H7.70794C6.78037 19.3248 6.02837 18.5728 6.02837 17.6451C6.02837 15.7987 6.02837 11.2045 6.02837 9.35806C6.02837 8.43044 6.78037 7.67844 7.70794 7.67844H10.3201C11.6188 7.67844 12.8896 8.02913 14 8.68806C15.1104 8.02913 16.3812 7.67844 17.6799 7.67844H20.2921C21.2196 7.67844 21.9716 8.43044 21.9716 9.35806V17.6451C21.9716 18.5728 21.2196 19.3248 20.2921 19.3248H17.6799C16.5536 19.3248 15.4551 19.6752 14.5369 20.3277C14.5347 20.3293 14.5326 20.3308 14.5304 20.3323ZM20.186 9.46406V17.5391H17.6799C16.7176 17.5391 15.7706 17.7316 14.8928 18.0999V10.2347C15.7328 9.73175 16.6957 9.46406 17.6799 9.46406H20.186ZM7.814 9.46406H10.3201C11.3043 9.46406 12.2672 9.73175 13.1072 10.2347V18.0999C12.2294 17.7316 11.2824 17.5391 10.3201 17.5391H7.814V9.46406Z" />
            </svg>
          </span>
        );
      case 'test':
        return (
          <span className="me-2 w-10">
            <svg className='icon quiz' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className='icon-color' fill-rule="evenodd" clip-rule="evenodd" d="M14 0C21.7268 0 28 6.27319 28 14C28 21.7268 21.7268 28 14 28C6.27319 28 0 21.7268 0 14C0 6.27319 6.27319 0 14 0ZM12.4476 22.413H15.5524C16.0452 22.413 16.4452 22.0129 16.4452 21.5202C16.4452 21.0274 16.0452 20.6274 15.5524 20.6274H12.4476C11.9548 20.6274 11.5548 21.0274 11.5548 21.5202C11.5548 22.0129 11.9548 22.413 12.4476 22.413ZM10.7185 17.7293H10.6681C10.1753 17.7293 9.77525 18.1293 9.77525 18.6221C9.77525 19.1148 10.1753 19.5149 10.6681 19.5149H17.3319C17.8247 19.5149 18.2248 19.1148 18.2248 18.6221C18.2248 18.1293 17.8247 17.7293 17.3319 17.7293H17.1607C17.161 17.5215 17.1614 17.2921 17.162 17.0576C17.162 17.0569 17.162 17.0562 17.162 17.0554C17.162 16.2119 17.4727 15.3979 18.0349 14.769C18.9004 13.8064 19.4267 12.5349 19.4267 11.1415C19.4267 8.14638 16.9951 5.71475 14 5.71475C11.0049 5.71475 8.57331 8.14638 8.57331 11.1415C8.57331 12.4954 9.07013 13.7341 9.89269 14.684C10.4251 15.3047 10.7185 16.0975 10.7185 16.9174V17.7293ZM12.5041 16.9174C12.5041 15.6704 12.0575 14.4645 11.2452 13.5183C11.2444 13.5173 11.2436 13.5164 11.2427 13.5154C10.6915 12.8789 10.3589 12.0487 10.3589 11.1415C10.3589 9.13188 11.9904 7.50037 14 7.50037C16.0096 7.50037 17.6411 9.13188 17.6411 11.1415C17.6411 12.0766 17.2877 12.9299 16.7073 13.5749C16.7068 13.5755 16.7062 13.5761 16.7057 13.5767C15.8501 14.5329 15.3767 15.7708 15.3764 17.0539C15.3758 17.2897 15.3754 17.5204 15.3751 17.7293H12.5041V16.9174Z"  />
            </svg>
          </span>
        );
      default:
        return (
          <span className="me-2 w-10">
            <svg className='icon notes' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className='icon-color' fill-rule="evenodd" clip-rule="evenodd" d="M14 0C21.7268 0 28 6.27319 28 14C28 21.7268 21.7268 28 14 28C6.27319 28 0 21.7268 0 14C0 6.27319 6.27319 0 14 0ZM14.5304 20.3323C14.3821 20.442 14.1986 20.5069 14 20.5069C14 20.5069 13.8604 20.5273 13.6595 20.4394C13.5913 20.4113 13.5273 20.375 13.4688 20.3316L13.4631 20.3277C12.5449 19.6752 11.4464 19.3248 10.3201 19.3248H7.70794C6.78037 19.3248 6.02837 18.5728 6.02837 17.6451C6.02837 15.7987 6.02837 11.2045 6.02837 9.35806C6.02837 8.43044 6.78037 7.67844 7.70794 7.67844H10.3201C11.6188 7.67844 12.8896 8.02913 14 8.68806C15.1104 8.02913 16.3812 7.67844 17.6799 7.67844H20.2921C21.2196 7.67844 21.9716 8.43044 21.9716 9.35806V17.6451C21.9716 18.5728 21.2196 19.3248 20.2921 19.3248H17.6799C16.5536 19.3248 15.4551 19.6752 14.5369 20.3277C14.5347 20.3293 14.5326 20.3308 14.5304 20.3323ZM20.186 9.46406V17.5391H17.6799C16.7176 17.5391 15.7706 17.7316 14.8928 18.0999V10.2347C15.7328 9.73175 16.6957 9.46406 17.6799 9.46406H20.186ZM7.814 9.46406H10.3201C11.3043 9.46406 12.2672 9.73175 13.1072 10.2347V18.0999C12.2294 17.7316 11.2824 17.5391 10.3201 17.5391H7.814V9.46406Z" />
            </svg>
          </span>
        );
        // return null;
    }
  };

  return (
    <div>
      <button
        className={`sidebar-btn font-18 fw-bold primary_color text-decoration-none pt-4 mb-4 ${isSidebarBtnActive ? 'active' : ''
          }`}
        onClick={handleToggleSidebar}
      >
        {console.log(selectedModuleId,"selectedModuleId")}
        <span className="me-2"> </span>
        {isSidebarVisible ? (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 33 33" fill="none">
              <path
                d="M6.99958 17.6668L12.3329 23.0001L10.4663 24.8934L1.90625 16.3334L10.4663 7.77344L12.3329 9.66677L6.99958 15.0001H28.3329V17.6668H6.99958ZM28.3329 8.33344V11.0001H14.9996V8.33344H28.3329ZM28.3329 21.6668V24.3334H14.9996V21.6668H28.3329Z"
                fill="#146B90"
              /></svg>{' '}{t('course_modules_hide_menu')}
          </>
        ) : (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 33 32" fill="none">
              <path d="M25.7778 17.3328L20.4444 22.6661L22.3111 24.5595L30.8711 15.9995L22.3111 7.43945L20.4444 9.33279L25.7778 14.6661H4.44443V17.3328H25.7778ZM4.44443 7.99945V10.6661H17.7778V7.99945H4.44443ZM4.44443 21.3328V23.9995H17.7778V21.3328H4.44443Z" fill="#146B90" />
            </svg>{' '}
            
          </>
        )}
        
      </button>

      <div
        className="moduleSidebar"
        style={{ width: isSidebarVisible ? '270px' : '0', overflowX: 'hidden', padding: '20px', transition: 'width 0.3s' }}
      >
        <div ref={sidebarRef} className="module-list">
          <h1 className="font-18 fw-bold mt-5 ms-4 pt-5 pb-4 course-title">{modules?.course_name}</h1>

          {isSidebarVisible && (
             <div className="accordion px-2">
             {modules.modules?.map((module) => (
               <div key={module.id} className={`accordion-item ${openModules.includes(module._id) ? 'open' : ''}`}>
                 <div className="accordion-header py-4 ps-4" onClick={() => toggleModule(module._id)}>
                   <span className={`accordion-title fw-bold text-decoration-none font-16 mb-5 ${selectedModuleId === module._id ? 'selected' : ''}`} data-module-id={module._id}>
                      {module.title}
                   </span>
                   {openModules.includes(module._id) ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                      <path d="M8.33301 20.002L16.333 12.002L24.333 20.002" stroke="#146B90" strokeWidth="2.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                   ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none" className='ms-2'>
                    <path d="M1.66626 2.00195L9.66626 10.002L17.6663 2.00195" stroke="black" strokeWidth="2.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                   )}
                 </div>
                 <div className={`accordion-content px-4 ${openModules.includes(module._id) ? 'open' : ''}`}>
                   {module.sub_modules?.map((subModule) => (
                      <div key={subModule.id} className='my-lg-4 my-3'>
                        <div onClick={() => handleModuleClick(subModule._id)} 
                      className={`submodule ${selectedModuleId === subModule._id ? ' active' : ''}`}>
                      <div
                        className={`submodule-title font-14 text-dark-grey text-decoration-none d-flex align-items-start ${selectedModuleId === subModule._id ? 'selected' : ''}`}
                        onClick={() => handleModuleClick(subModule)} data-module-id={subModule._id}>
                        <span className="sidetab-img">{getModuleIcon(subModule)}</span>
                       {subModule.title}
                        <br />
                           {/* <span className="text-dark-gray">{subModule?.duration} mins</span> */} 
                      </div>
                      </div>
      
                      {subModule.sub_sub_modules && (
                        <div className="nested-submodule ps-5">
                          {subModule.sub_sub_modules.map((nestedSubModule) => (
                            <div key={nestedSubModule.id} onClick={() => handleModuleClick(nestedSubModule._id)} 
                            className={`nestedsubmodule my-lg-4 my-3 pt-1 ps-3 ${selectedModuleId === nestedSubModule._id ? ' active' : ''}`}>
                              <div
                                className={`nestedsubmodule-title font-14 text-dark-grey text-decoration-none d-flex align-items-start mb-4 ${
                                  selectedModuleId === nestedSubModule._id ? 'selected' : ''}`} data-module-id={nestedSubModule._id}
                                onClick={() => handleModuleClick(nestedSubModule)}
                              >
                                <span className="sidetab-img">{getModuleIcon(nestedSubModule)}</span>
                                {nestedSubModule.title}
                                <br />
                                     {/* <span className="text-dark-gray">{nestedSubModule?.duration} mins</span> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                   ))}
                 </div>
               </div>
             ))}
           </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default Sidebar;



