import React, { useState } from 'react';
import Modal from 'react-modal';
import apiService from '../../../services/apiService';
import {Link} from 'react-router-dom';
import CustomToast from '../../Toast';
import Loader2 from '../../layouts/loader2';
import { useTranslation } from 'react-i18next';

const CourseCompletedModal = ({setIsComleted,course_id, course_name}) => {

    const [courseCompletedModalIsOpen, setCourseCompletedModalIsOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const { t } = useTranslation();

    const closeCourseCompletedModal = () => {
        setIsComleted(false);
    };
    const getCertificate=async(courseDetails)=> {
        setLoader(true);
        let user_details = sessionStorage.getItem('user_details')
          let data = {
            "course_id" :course_id,
            // ||courseDetails?.course_id,
            "send_email": true,
            "user_id": JSON.parse(user_details)?.uid,
            course_name,
            course_lang: localStorage.getItem('selectedLanguage')
          }
          const token = apiService.getAuthToken()
        await apiService.postV1(`generate-certificate`,data,token).then((data)=>{
            if(data?.status){
                console.log("cdewcdwcewcew");
                window.open(data?.data?.certificate_url, "_blank")
                setLoader(false);
            }
          }).catch(error=>{
            console.log("cdewcdwcewcew");
            CustomToast.error(error);
            setLoader(false);
          })
      }

    return (
        <form>
            
        {loader ? <Loader2 /> : <>
       
        <div className="custom-overlay video-questions quiz">
            <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                <div className='card mw-500 p-3' style={{borderRadius: '25px'}}>
                    <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                        <Link to="/" className='w-100 text-end test-decoration-none'><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                            <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                        </svg>
                        </Link>
                        <img src='/images/congratulations.svg' alt='congratulations' width={280} />
                        <h3 className='fw-bold font-26 my-4'>{t('course_modules_congratulations')}</h3>
                        <p className='text-center px-4'>{t('course_modules_completed_course_successfully')}</p>
                        <div className='d-flex justify-content-center align-items-center w-100 my-3'>
                            <button type='button' className='btn btn-md btn-primary' onClick={getCertificate}>{t('course_modules_view_certificate')}</button>
                        </div>
                    </div>
                </div></div></div>
   </>
        }
         </form>
    )
}

export default CourseCompletedModal;