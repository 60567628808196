import React, { useState, useEffect, useContext} from 'react';
import Menu from "./menu";
import styled from "styled-components";
import Footer from "./footer";
import Modal from 'react-modal';
import apiService from '../../services/apiService';
import CustomToast from '../Toast';
import { useTranslation } from 'react-i18next';

const Navbar = ({ children, isOrganisationDetails, showSidebar = true, showFooter = true, authToken }) => {
    const [show, setShow] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [userData, setUserData] = useState(false);
    const [error, setError] = useState('');
    const [languages, setLanguages] = useState([]);
    const [showOrganisationDetails, setShowOrganisationDetails] = useState(false);
    const [isOrgUser, setIsOrgUser] = useState(false)
    // const { selectedLanguages, updateLanguage } = useContext(LanguageContext);
   // const [userInfo, setUserInfo ] = useState()

    const { t } = useTranslation();

    const fetchData = async () => {
        try {
            let userDetails  = sessionStorage.getItem('user_details')
            let baseDetails = sessionStorage.getItem('userDetail')
            if(baseDetails) {
                baseDetails = JSON.parse(baseDetails)
                if(baseDetails?.is_org_user) {
                    setIsOrgUser(true)
                }
            }
            if(userDetails) {
                userDetails = JSON.parse(userDetails)
                setUserData(userDetails);
            } else {
                const token = apiService.getAuthToken()
                const result = await apiService.get('get-org-user-info', token || authToken);
                sessionStorage.setItem('user_details', JSON.stringify(result.data))
                if(result && result.status == "success") {
                    setUserData(result.data);
                }
            }
        } catch (error) {
            setError('Error fetching user data');
            console.error(error);
            CustomToast.error('Error fetching user data');
            if (error == "Invalid token id!") {
                // Clear the token from local storage
                localStorage.removeItem('authToken');
                sessionStorage.removeItem('user_details');
                // Redirect to the original website
                // if (process.env.REACT_APP_ENV == "development") {
                //     window.location.href = 'https://staging.inclusional.com';
                // } else {
                //     window.location.href = 'https://site.inclusional.com';
                // }

                window.location.href = process.env.REACT_APP_LOGIN_URL
            }
        }
    };

    const getInitials = (name) => {
        return name?.split(' ').map(word => word.charAt(0)).join('').toUpperCase() || '';
    };

    useEffect(() => {
       // const userDetails = localStorage.getItem("userDetails")
       // if(userDetails){
          //  userDetails = JSON.parse(userDetails)
           // setUserInfo(userDetails);
           // userDetails['isOrg'] = true;
        //}
        fetchData();
        const checkSession = async () => {
            try {
               const token = apiService.getAuthToken() 
               const response = await apiService.get('check-user-session', token)
               console.log(token);
                if (response && response?.status === 'failed' || response?.message == 'Invalid token id!') {
                    CustomToast.info('Your session has expired due to inactivity. Please log in again to continue.');
                    window.location.href = process.env.REACT_APP_LOGIN_URL;
                }
            } catch (error) {
              console.error('Error checking session:', error);
              if (error?.message == 'Invalid token id!') {
                // if(process.env.REACT_APP_ENV == "development") {
                //     window.location.href = `https://staging.inclusional.com`;
                //   } else {
                //     window.location.href = `https://site.inclusional.com`;
                // }
                CustomToast.info('Your session has expired due to inactivity. Please log in again to continue.');
                window.location.href = process.env.REACT_APP_LOGIN_URL;
              } 
            }
          };
          checkSession();
    }, []);

    const openVideoModal = () => {
        setModalOpen(true);
    };

    const closeVideoModal = () => {
        setModalOpen(false);
    };

    const showSideBar = () => {
        setShow(!show);
    }

    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.removeItem('authToken');
        sessionStorage.removeItem('user_details');
        sessionStorage.removeItem('userDetail')
        localStorage.removeItem('userLangArr')
        localStorage.removeItem('userLang')
        localStorage.removeItem('selectedLanguageInfo')
        localStorage.removeItem("CourseLanguagesData");
        // Redirect to the original website
        // if(process.env.REACT_APP_ENV == "development") {
        //     window.location.href = 'https://staging.inclusional.com';
        // } else {
        //     window.location.href = 'https://site.inclusional.com';
        // }
        window.location.href = process.env.REACT_APP_LOGIN_URL
    };

    const goToOrg = ()=> {
        let userDetails = sessionStorage.getItem("userDetail")
            if(userDetails) {
                userDetails = JSON.parse(userDetails)
                const encodedToken = encodeURIComponent(JSON.stringify(userDetails));
                if(process.env.REACT_APP_ENV == 'development') {
                    window.location.href = `https://fstage-org.inclusional.com?ref=${encodedToken}`
                } else {
                    window.location.href = `https://fstage-org.inclusional.com?ref=${encodedToken}`
                }
            } else {
                // if(process.env.REACT_APP_ENV == 'development') {
                //     window.location.href = "https://staging.inclusional.com"
                // } else {
                //     window.location.href = "https://site.inclusional.com"
                // }
                window.location.href = process.env.REACT_APP_LOGIN_URL
                //process.env.REACT_APP_LOGIN_URL
            }
    }

    /*

    const SidebarData = [
        {
            title: "Dashboard",
            path: "/dashboard",
            icon: "/images/dashboard.svg"
        },
        {
            title: "Organisation",
            path: "/organisation",
            icon: "/images/setting.svg"
        }
    ];

    const OrgSidebarData = [
        {
            title: "Org. Dashboard",
            path: "/organisation_details",
            icon: "/images/dashboard.svg"
        },
        {
            title: "Org. Manage Users",
            path: "/",
            icon: "/images/user.svg"
        },
        {
            title: "Orgnisation Admin",
            path: "/",
            icon: "/images/setting.svg"
        }
    ];

   
    const renderSidebarMenu = () => {
        if (isOrganisationDetails) {
            return (
                <div className='sidebar-menu'>
                    <p className='mb-5 back-link ps-3'>
                        <a href='/organisation' className='font-16 text-decoration-none'>  <span> <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                            <path d="M26 15.2778H11.0428L14.8217 11.4883L13.3333 10L7 16.3333L13.3333 22.6667L14.8217 21.1678L11.0428 17.3889H26V15.2778Z" fill="#146B90" />
                        </svg></span> Back to Org. Listing</a>
                    </p>

                    {OrgSidebarData.map((item, index) => (
                        <Menu item={item} key={index} />
                    ))}

                    <div className='sticky-btn text-center me-4'>
                        <button onClick={openVideoModal} className='btn btn-primary w-100'>Watch Tutorial</button>
                    </div>

                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeVideoModal}
                        contentLabel="Video Modal"
                        className='videoModal'
                    >
                        <iframe
                            src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
                            title="Video Title"
                            allowFullScreen
                        ></iframe>
                    </Modal>
                </div>
            );
        } else {
            return (
                <div className='sidebar-menu'>
                    {SidebarData.map((item, index) => (
                        <Menu item={item} key={index} />
                    ))}
                </div>
            );
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const sidebarMenu = document.querySelector('.sidebar-menu');
            if (sidebarMenu) {
                if (window.scrollY > 50) {
                    sidebarMenu.classList.add('min-height');
                } else {
                    sidebarMenu.classList.remove('min-height');
                }
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

     */

    // const getLang=async()=>{
    //     const token = apiService.getAuthToken()
    //     try {
    //     const languagesData = await apiService.getCms('getLangList',token)
    //     if (languagesData.status) {
    //       setLanguages(languagesData.data);
    //     } else {
    //       console.error("Error fetching languages:", languagesData.error);
    //     }
    //   } catch (error) {
    //     console.error("An unexpected error occurred:", error);
    //   }
    // }

    // useEffect(()=>{
    //     getLang()
    //     const lang=localStorage.getItem('selectedLanguage')
    //     if(lang){
    //         setSelectedLanguage(lang)
    //         updateLanguage(lang);
    //     }

    // },[])

    // const handleLangSelection = (value) => {
    //     setSelectedLanguage(value); 
    //     localStorage.setItem('selectedLanguage',(value))
    //     updateLanguage(value);
    // }

    return (
        <>
            <header className={`header ${show ? "add_body_padding" : ""}  `} id="admin-dash-header">
                <a href='/'> <img src="/images/logo.svg" alt="logo" className="navbar_logo" /></a>

                <div className='header_toggle'>
                    <i id="header-toggle"></i>
                </div>
                <div className="dropdown sidebar-profile d-flex align-items-center justify-content-center">
                    <div className="language-picker pe-lg-3 pe-0">
                    {isOrgUser ? 
                    <button  className='btn btn-secondary w-100 py-2 me-lg-4 me-0' onClick={()=> goToOrg()}>Go To Org.Dashboard</button> : null
                    }
                        {/* <p>{selectedLanguage}</p> */}
                        {/* <form action="" className="language-picker__form">
                            <select
                                id="language-picker"
                                name="language-picker"
                                className='form-control custom-select'
                                value={selectedLanguage}
                                onChange={(e) => {handleLangSelection(e.target.value)}}>
                                    {languages && languages?.map((data) => {
                                            return <option value={data?._id}>{data?.name}</option>
                                        })}
                                <option lang="" value="english">ENG</option>
                            </select>
                        </form> */}

                    </div>
                    <p className='username mb-0 ms-3'>Hello, {userData.name}</p>
                    <span className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                        {userData && userData?.pic && userData?.pic != "null"  && userData?.pic != null ? (
                                    <img src={userData.pic} alt="avatar" className="avatar rounded-circle" />
                                ) : (
                                    <div className='initials font-28 text-white fw-bold text-uppercase'>{getInitials(userData.name)}</div>
                                )}
                    </span>
                    <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                        <li>
                            <a className="dropdown-item" href="/my_profile">
                                {t('header_page_my_profile')}
                            </a>
                        </li>
                      {/* <li>
                            <a className="dropdown-item">
                                Back to org.
                            </a>
                        </li> */}  
                        <li>
                            <a href="" className="dropdown-item" onClick={handleLogout}>{t('header_page_logout')}</a>
                        </li>
                    </ul>
                </div>
            </header>

            <div className='wrapper'>
                {/* {showSidebar && (
                    <aside className={`sidebar ${show ? "review" : ""} `} id="admin-dash-nav">
                        <div className='d-flex flex-column justify-content-between h-100'>
                            {renderSidebarMenu()}
                        </div>
                    </aside>
                )} */}

                <main className={` ${show ? "add_body_padding" : "main"} `}>
                    {children}
                </main>
            </div>

            {showFooter && <Footer />}
        </>
    );
}

export default Navbar;