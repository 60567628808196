import React, { useEffect, useState } from 'react';
import apiService from '../../../services/apiService';
import Loader from '../../layouts/loader';
import CustomToast from '../../Toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const Reading = ({ moduleContent, updateLmsModule }) => {
  const [completed, setCompleted] = useState(false);
  const [feedBack,setFeedBack] = useState("")
  const [disableInput, setDisableInput] = useState(false)
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])


  useEffect(()=> {
    if(moduleContent?.moduleContent?.content_type == "feedback") {
      fetchFeedbackData()
    }
  }, [moduleContent])

  const fetchFeedbackData = async()=> {
    setLoader(true)
      const result = await apiService.getLmsv1(`/getUserFeedback?course_id=${moduleContent?.course_id}&user_id=${moduleContent?.user_id}&org_id=${moduleContent?.org_id}`)
      if(result && result?.data && result?.data?.feedback) {
        setFeedBack(result?.data?.feedback)
        setDisableInput(true)
        setLoader(false)
      } else {
        setFeedBack('')
        setLoader(false)
      }
  }

  const handleMarkAsCompleted = async() => {
    if(moduleContent?.moduleContent?.content_type == "feedback"){
      if(!feedBack) {
        CustomToast.info(t('toast_messages_add_feedback'));
      } else {
        let datas= {
          "course_id": moduleContent?.course_id,
          "user_id": moduleContent?.user_id,
          "org_id": moduleContent?.org_id,
          "feedback": feedBack
        }
        await apiService.postLms('saveUserFeedback',datas ).then((data)=>{
          updateLmsModule().then((data) => {
    
          })
        })
      }
    } else{
    updateLmsModule().then((data) => {

    })}

    if(moduleContent?.moduleContent?.content_type == "last_module" && moduleContent?.completion_percent == 100) {
      navigate('/');
    } 

    setCompleted(true);
  };

  return (
    <div>
      <div className='reading' dangerouslySetInnerHTML={{ __html: moduleContent?.moduleContent?.content }} />
      {loader ? <Loader /> : moduleContent?.moduleContent?.content_type == "feedback"  ? <input
        type="text"
        className="form-control"
        placeholder={t('course_modules_feedback')}
        value={feedBack}
        name="FeedBack"
        onChange={(e) => setFeedBack(e.target.value)}
        disabled= {disableInput}
        required
      /> : null}
      <br />
      <p className='text-end'>
        {!moduleContent?.moduleContent?.is_completed && (
          <button onClick={handleMarkAsCompleted} className='btn btn-md btn-primary'>{moduleContent?.moduleContent?.content_type == "copyright" ? t('course_modules_accept') : moduleContent?.moduleContent?.content_type == "last_module" ? t('course_modules_complete_course') : moduleContent?.moduleContent?.content_type == "feedback" ? t('course_modules_submit') : t('course_modules_next')}</button>
        )}

        {(moduleContent?.moduleContent?.is_completed) && (
          <button onClick={handleMarkAsCompleted} className='btn btn-md btn-primary'>{t('course_modules_next')}</button>
        )}
      </p>
    </div>
  );
};

export default Reading;
